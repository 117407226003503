import backend from '../utilts/backendAPI';
import { FETCH_TASK_LIST, SOCKET_ADD_TASK, SOCKET_UPDATE_TASK_STATUS } from './actionType';
import { setSnackbar } from './snackbar';
import { setLoadingStatus } from './loading';

export const fetchTaskList = () => async (dispatch) => {
  const response = await backend.GET('/tasks');

  dispatch({ type: FETCH_TASK_LIST, payload: response.data });
};

export const addTask = (data) => async (dispatch) => {
  try {
    await backend.POST('/tasks', data);
  } catch (error) {
    dispatch(
      setSnackbar({
        isSnackbarOpen: true,
        snackbarType: 'warning',
        snackbarMessage: '發生錯誤',
      })
    );
    dispatch(setLoadingStatus(false));
  }
};

export const socket_addTask = (data) => {
  return { type: SOCKET_ADD_TASK, payload: data };
};

export const deleteTask = (arrIDs) => async (dispatch) => {
  try {
    await backend.POST(`/tasks/delete`, { taskIds: arrIDs });
    dispatch(fetchTaskList());

    dispatch(
      setSnackbar({
        isSnackbarOpen: true,
        snackbarType: 'success',
        snackbarMessage: '已刪除所選取的爬蟲任務',
      })
    );
  } catch (error) {
    dispatch(
      setSnackbar({
        isSnackbarOpen: true,
        snackbarType: 'error',
        snackbarMessage: '發生錯誤',
      })
    );
  }
};

export const socket_updateTaskStatus = (data) => {
  return { type: SOCKET_UPDATE_TASK_STATUS, payload: data };
};
