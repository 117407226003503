import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';

const CustomProgressBar = () => {
  const { progress } = useSelector((state) => state);
  const isFinished = useSelector((state) => {
    const { updateInprogress, publishInprogress, canPublishOrUpdate } = state.progress;
    return !updateInprogress && !publishInprogress && canPublishOrUpdate;
  });

  const displayValueTemplate = (value) => {
    return (
      <React.Fragment>
        {progress.finishedCount}/<b>{progress.totalCount}</b>
      </React.Fragment>
    );
  };

  const renderHeader = () => {
    if (progress.publishInprogress) {
      return '正在上架商品...';
    }

    if (progress.updateInprogress) {
      return '正在更新已上架商品...';
    }
  };

  return (
    <Dialog
      header={renderHeader()}
      visible={!isFinished}
      modal={false}
      closable={false}
      position="bottom-right"
      style={{ width: '30vw', minWidth: '200px' }}
    >
      <ProgressBar
        value={(progress.finishedCount / progress.totalCount) * 100}
        displayValueTemplate={displayValueTemplate}
        mode={
          !progress.canPublishOrUpdate && progress.totalCount === 0
            ? 'indeterminate'
            : 'determinate'
        }
      ></ProgressBar>
    </Dialog>
  );
};

export default CustomProgressBar;
