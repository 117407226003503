import { MULTIPLY_PRICE, CHANGE_EXCHANGE_RATE, CHANGE_CURRENCY } from '../actions/actionType';

const initialState = {
  allItems: {
    count: null,
    rows: [],
    currentPage: null,
    totalPages: null,
  },
  unpublishedItems: { count: null, rows: [], currentPage: null, totalPages: null },
  publishedItems: { count: null, rows: [], currentPage: null, totalPages: null },
  selectedUnpublishedItems: {},
  selectedPublishedItems: {},
  multiple: 1,
  exchangeRate: 1,
  currency: 'JPY',
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'FETCH_UNPUBLISHED_ITEMS': {
      return { ...state, unpublishedItems: payload };
    }

    case 'RESET_UNPUBLISHED_ITEMS': {
      return { ...state, unpublishedItems: { ...state.unpublishedItems, rows: [] } };
    }

    case 'FETCH_PUBLISHED_ITEMS': {
      return { ...state, publishedItems: payload };
    }

    case 'RESET_PUBLISHED_ITEMS': {
      return { ...state, publishedItems: { ...state.publishedItems, rows: [] } };
    }

    case 'SELECT_UNPUBLISHED_ITEM': {
      return {
        ...state,
        selectedUnpublishedItems: { ...state.selectedUnpublishedItems, [payload.id]: payload },
      };
    }

    case 'REMOVE_UNPUBLISHED_SELECTED_ITEM': {
      const newSelectedUnpublishedItems = { ...state.selectedUnpublishedItems };
      payload.itemIds.map((id) => delete newSelectedUnpublishedItems[id]);

      return { ...state, selectedUnpublishedItems: { ...newSelectedUnpublishedItems } };
    }

    case 'SELECT_PUBLISHED_ITEM': {
      return {
        ...state,
        selectedPublishedItems: { ...state.selectedPublishedItems, [payload.id]: payload },
      };
    }

    case 'RESET_SELECTED_ITEMS': {
      return {
        ...state,
        selectedPublishedItems: {},
        selectedUnpublishedItems: {},
      };
    }

    case 'REMOVE_PUBLISHED_SELECTED_ITEM': {
      const newSelectedPublishedItems = { ...state.selectedPublishedItems };
      payload.itemIds.map((id) => delete newSelectedPublishedItems[id]);

      return { ...state, selectedPublishedItems: { ...newSelectedPublishedItems } };
    }

    case MULTIPLY_PRICE: {
      return { ...state, multiple: payload };
    }

    case CHANGE_EXCHANGE_RATE: {
      return { ...state, exchangeRate: payload.exchangeRate };
    }

    case CHANGE_CURRENCY: {
      return { ...state, currency: payload.currency };
    }

    default: {
      return state;
    }
  }
};
