import React, { useEffect } from 'react';
import { Router, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import history from './history';
import io from 'socket.io-client';

import { setSnackbar } from './actions/snackbar';
import * as progressActions from './actions/progress';
import { socket_addTask, socket_updateTaskStatus } from './actions/crawlerTask';
import { setLoadingStatus } from './actions/loading';
import { healthCheck } from './actions/heathCheck';
import { setProgress, disablePublishOrUpdate } from './actions/progress';

import Task from './views/Task';
import Result from './views/Result';
import BaseSnackbar from './components/BaseSnackbar';
import Loading from './components/Loading';
import CustomProgressBar from './components/CustomProgressBar';

import styles from './app.module.scss';

const ENDPOINT = 'https://zozo-test.meshstream.io/client';

const socket = io(ENDPOINT);

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    socket
      .on('newTask', (data) => {
        dispatch(socket_addTask({ ...data, status: 'progressing' }));
        dispatch(
          setSnackbar({
            isSnackbarOpen: true,
            snackbarType: 'success',
            snackbarMessage: '新增任務成功',
          })
        );
        dispatch(setLoadingStatus(false));
      })
      .on('healthcheck', (data) => {
        dispatch(healthCheck(data));
      })
      .on('startTask', (data) => {
        dispatch(socket_updateTaskStatus(data));
      })
      .on('abnormalTask', (data) => {
        dispatch(socket_updateTaskStatus(data));
      })
      .on('noData', (data) => {
        dispatch(
          setSnackbar({
            isSnackbarOpen: true,
            snackbarType: 'warning',
            snackbarMessage: '該任務搜尋不到任何資料',
          })
        );
        dispatch(setLoadingStatus(false));
      })
      .on('finishTask', (data) => {
        dispatch(socket_updateTaskStatus(data));
      })
      .on('publishByTaskId', (data) => {
        dispatch(setProgress(data));
        dispatch(progressActions.setPublishInprogress({ inprogress: true }));
      })
      .on('publishByTaskId:success', (data) => {
        dispatch(
          setSnackbar({
            isSnackbarOpen: true,
            snackbarType: 'success',
            snackbarMessage: '所有商品已經成功上架',
          })
        );

        window.location.reload();
      })
      .on('publishManyItems', (data) => {
        dispatch(setProgress(data));
        dispatch(progressActions.setPublishInprogress({ inprogress: true }));
      })
      .on('publishManyItems:success', (data) => {
        dispatch(
          setSnackbar({
            isSnackbarOpen: true,
            snackbarType: 'success',
            snackbarMessage: '商品已經成功上架',
          })
        );

        window.location.reload();
      })
      .on('publishManyItems:failed', (data) => {
        dispatch(
          setSnackbar({
            isSnackbarOpen: true,
            snackbarType: 'warning',
            snackbarMessage: '發生錯誤',
          })
        );
      })
      .on('updatePublishedPriceByTaskId', (data) => {
        dispatch(setProgress(data));
        dispatch(progressActions.setPublishInprogress({ inprogress: true }));
      })
      .on('updatePublishedPriceByTaskId:success', (data) => {
        dispatch(
          setSnackbar({
            isSnackbarOpen: true,
            snackbarType: 'success',
            snackbarMessage: '商品已經成功更新',
          })
        );

        window.location.reload();
      })
      .on('updatePublishedPriceByTaskId:failed', (data) => {
        dispatch(
          setSnackbar({
            isSnackbarOpen: true,
            snackbarType: 'warning',
            snackbarMessage: '發生錯誤',
          })
        );
      })
      .on('updateManyPublishedItemsPrice', (data) => {
        dispatch(setProgress(data));
        dispatch(progressActions.setUpdateInprogress({ inprogress: true }));
      })
      .on('updateManyPublishedItemsPrice:success', (data) => {
        dispatch(
          setSnackbar({
            isSnackbarOpen: true,
            snackbarType: 'success',
            snackbarMessage: '商品已經成功更新',
          })
        );

        window.location.reload();
      })
      .on('updateManyPublishedItemsPrice:failed', (data) => {
        dispatch(
          setSnackbar({
            isSnackbarOpen: true,
            snackbarType: 'warning',
            snackbarMessage: '批次更新發生錯誤',
          })
        );
      })
      .on('batchTaskAvailable', ({ inProgress }) => {
        dispatch(disablePublishOrUpdate({ inprogress: inProgress }));
      })
      .on('tooManyItems', (data) => {
        dispatch(
          setSnackbar({
            isSnackbarOpen: true,
            snackbarType: 'warning',
            snackbarMessage: '某爬蟲任務的商品數量過多，已停止爬取該任務',
          })
        );
        dispatch(socket_updateTaskStatus(data));
      });
  }, []);

  return (
    <div className={styles.app}>
      <Router history={history}>
        <Route path="/" exact component={Task} />
        <Route path="/result/:taskId" component={Result} />
      </Router>
      <BaseSnackbar />
      <CustomProgressBar />
      <Loading />
    </div>
  );
};

export default App;
