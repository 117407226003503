import { SET_LOADING_STATUS } from '../actions/actionType';

const initialState = {
  isLoading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING_STATUS: {
      return { ...state, isLoading: payload };
    }

    default: {
      return state;
    }
  }
};
