import {
  SET_PROGRESS,
  DISABLE_PUBLISH_OR_UPDATE,
  SET_UPDATE_INPROGRESS,
  SET_PUBLISH_INPROGRESS,
} from './actionType';

export const setProgress = (data) => {
  return { type: SET_PROGRESS, payload: data };
};

export const setPublishInprogress = (data) => {
  return { type: SET_PUBLISH_INPROGRESS, payload: data };
};

export const setUpdateInprogress = (data) => {
  return { type: SET_UPDATE_INPROGRESS, payload: data };
};

export const disablePublishOrUpdate = (data) => {
  return { type: DISABLE_PUBLISH_OR_UPDATE, payload: data };
};
