import { HEALTH_CHECK } from '../actions/actionType';

const initialState = {
  success: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case HEALTH_CHECK: {
      return { ...state, ...payload };
    }

    default: {
      return state;
    }
  }
};
