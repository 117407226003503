import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import history from '../../history';
import { resetSelectedItem } from '../../actions/items';

import ResultTabView from '../../components/ResultTabView';
import Converter from '../../components/Converter';

import styles from './index.module.scss';

const Result = () => {
  const dispatch = useDispatch();
  const toTaskPage = () => {
    history.push('/');
  };

  useEffect(() => {
    return () => {
      dispatch(resetSelectedItem());
    };
  }, []);

  return (
    <div className={styles.crawler_result_page}>
      <header>
        <div className={styles.backButton} onClick={toTaskPage}>
          <i className="pi pi-chevron-circle-left" />
        </div>
        <Converter />
      </header>
      <ResultTabView />
    </div>
  );
};

export default Result;
