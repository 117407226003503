import _ from 'lodash';
import { FETCH_TASK_LIST, SOCKET_ADD_TASK, SOCKET_UPDATE_TASK_STATUS } from '../actions/actionType';

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TASK_LIST: {
      return { ..._.mapKeys(payload, 'id') };
    }

    case SOCKET_ADD_TASK: {
      return { ...state, [payload.id]: payload };
    }

    case SOCKET_UPDATE_TASK_STATUS: {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          status: payload.status,
          updatedAt: payload.updatedAt,
        },
      };
    }

    default: {
      return state;
    }
  }
};
