import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLoadingStatus } from '../../actions/loading';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Dialog } from 'primereact/dialog';

import { fetchShopConfigs, fetchBrandConfigs } from '../../actions/options';
import { addTask } from '../../actions/crawlerTask';

import { Button } from 'primereact/button';
import TextField from '@material-ui/core/TextField';

import BaseModal from '../BaseModal';
import SearchBox from '../SearchBox';
import styles from './index.module.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '150px',
    },
    '& .MuiSelect-selectMenu': {
      width: '80px',
      fontSize: '13px',
      textAlign: 'center',
    },
    '& .MuiFormLabel-root': {
      fontSize: '14px',
    },
  },
}));

const validationSchema = yup.object({
  lowestPrice: yup
    .number()
    .typeError('須為數字')
    .min(0, '須介於 0~1000000')
    .max(1000000, '須介於 0~1000000')
    .integer('價格須為整數'),
  highestPrice: yup
    .number()
    .typeError('須為數字')
    .min(0, '須介於 0~1000000')
    .max(1000000, '須介於 0~1000000')
    .integer('價格須為整數'),
  isSale: yup.boolean(),
  allInStock: yup.boolean(),
});

const TaskForm = () => {
  // keyword、shop 與 brand 為條件特殊的選項
  const [keyword, setKeyword] = useState('');
  const [shop, setShop] = useState('');
  const [brand, setBrand] = useState('');
  const [isShopModalOpen, setIsShopModalOpen] = useState(false);
  const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);

  const { shopOptionSet, brandOptionSet } = useSelector((state) => state.options);
  const taskList = useSelector((state) => Object.values(state.crawlerTask));

  const dispatch = useDispatch();

  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      keyword: '',
      lowestPrice: '',
      highestPrice: '',
      sex: '',
      isSale: false,
      allInStock: false,
      frequency: 172800000,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!(keyword || shop || brand)) {
        alert('關鍵字 / 商店 / 品牌種類至少擇一');
        return;
      }

      const formikValues = JSON.parse(JSON.stringify(values));
      const formData = { ...formikValues, shop, brand, keyword };

      const formDataFilter = Object.keys(formData).reduce((acc, curr) => {
        if (formData[curr] !== '') {
          acc[curr] = formData[curr];
        }
        return acc;
      }, {});

      dispatch(setLoadingStatus(true));
      dispatch(addTask(formDataFilter));

      // reset form
      formik.handleReset();
      setKeyword('');
      setShop('');
      setBrand('');
    },
  });

  useEffect(() => {
    dispatch(fetchShopConfigs({ initial: 'a' }));
    dispatch(fetchBrandConfigs({ initial: 'a' }));
  }, []);

  return (
    <div className={styles.task_form}>
      <form className={classes.root} autoComplete="off" onSubmit={formik.handleSubmit}>
        <span className={styles.hint}>
          特殊條件：
          <br />
          ・關鍵字 / 商店 / 品牌種類至少擇一
          <br />
          ・商店 / 品牌種類僅能擇一
        </span>
        <div className={styles.group}>
          <TextField
            fullWidth
            id="keyword"
            name="keyword"
            label="關鍵字"
            value={keyword}
            onChange={(e) => setKeyword(() => e.target.value)}
          />
          <div>
            <span className={styles.title} onClick={() => setIsShopModalOpen(true)}>
              <span>商店: </span>
              <span className={styles.selected}>{shop ? shop : '請選擇'}</span>
            </span>
            <Dialog
              header="選擇商店"
              visible={isShopModalOpen}
              modal={false}
              onHide={() => setIsShopModalOpen(false)}
              style={{ width: '70vw' }}
            >
              <SearchBox
                options={shopOptionSet}
                fetchConfigs={(data) => dispatch(fetchShopConfigs(data))}
                selectHandler={(value) => {
                  setShop(value);
                  setBrand('');
                  setIsShopModalOpen(false);
                }}
              />
            </Dialog>
          </div>
          <div>
            <div className={styles.title} onClick={() => setIsBrandModalOpen(true)}>
              <span>品牌種類: </span>
              <span className={styles.selected}>{brand ? brand : '請選擇'}</span>
            </div>
            <Dialog
              header="選擇品牌"
              visible={isBrandModalOpen}
              modal={false}
              onHide={() => setIsBrandModalOpen(false)}
              style={{ width: '70vw' }}
            >
              <SearchBox
                options={brandOptionSet}
                fetchConfigs={(data) => dispatch(fetchBrandConfigs(data))}
                selectHandler={(value) => {
                  setBrand(value);
                  setShop('');
                  setIsBrandModalOpen(false);
                }}
              />
            </Dialog>
          </div>
        </div>
        <div className={styles.group}>
          <TextField
            fullWidth
            id="lowestPrice"
            name="lowestPrice"
            type="number"
            label="最低價格(日幣)"
            value={formik.values.lowestPrice}
            onChange={formik.handleChange}
            error={formik.touched.lowestPrice && Boolean(formik.errors.lowestPrice)}
            helperText={formik.touched.lowestPrice && formik.errors.lowestPrice}
          />
          <TextField
            fullWidth
            id="highestPrice"
            name="highestPrice"
            type="number"
            label="最高價格(日幣)"
            value={formik.values.highestPrice}
            onChange={formik.handleChange}
            error={formik.touched.highestPrice && Boolean(formik.errors.highestPrice)}
            helperText={formik.touched.highestPrice && formik.errors.highestPrice}
          />
          <div>
            <span className={styles.title}>爬蟲頻率:</span>
            <Select
              labelId="frequency"
              id="frequency"
              name="frequency"
              value={formik.values.frequency}
              onChange={formik.handleChange}
              MenuProps={{ classes: { paper: classes.select } }}
            >
              <MenuItem value={172800000}>2 天</MenuItem>
              {/* <MenuItem value={3600000}>1 小時</MenuItem>
              <MenuItem value={10800000}>3 小時</MenuItem>
              <MenuItem value={21600000}>6 小時</MenuItem>
              <MenuItem value={43200000}>12 小時</MenuItem> */}
            </Select>
          </div>
        </div>
        <div className={styles.group}>
          <div>
            <span className={styles.title}>性別:</span>
            <label>
              <input
                checked={formik.values.sex === 'men'}
                type="radio"
                name="sex"
                onChange={formik.handleChange}
                value="men"
              />
              <span>男</span>
            </label>
            <label>
              <input
                checked={formik.values.sex === 'women'}
                type="radio"
                name="sex"
                onChange={formik.handleChange}
                value="women"
              />
              <span>女</span>
            </label>
            <label>
              <input
                checked={formik.values.sex === 'kids'}
                type="radio"
                name="sex"
                onChange={formik.handleChange}
                value="kids"
              />
              <span>兒童</span>
            </label>
          </div>
        </div>
        <div className={styles.group}>
          <div>
            <label>
              <input
                type="checkbox"
                name="isSale"
                onChange={formik.handleChange}
                value={formik.values.isSale}
                checked={formik.values.isSale}
              />
              <span>僅顯示特價中商品</span>
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                name="allInStock"
                onChange={formik.handleChange}
                value={formik.values.allInStock}
                checked={formik.values.allInStock}
              />
              <span>僅顯示有庫存的商品</span>
            </label>
          </div>
        </div>
        <Button
          type="submit"
          label="新增爬蟲任務"
          icon="pi pi-shopping-cart"
          className="p-button-rounded p-button-success"
          disabled={!keyword && !shop && !brand}
        />
      </form>
    </div>
  );
};

export default TaskForm;
