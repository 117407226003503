import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Chip from '@material-ui/core/Chip';

import ItemTabs from '../ItemTabs';
import styles from './index.module.scss';

const formatTime = (time) => {
  return dayjs(time).format('YYYY/MM/DD HH:mm');
};

const ItemCard = ({ item }) => {
  const dispatch = useDispatch();
  const { items } = useSelector((state) => state);
  return (
    <div className={styles.item_card}>
      <section className={styles.item_card_body}>
        <div className={styles.media_image_container}>
          <div className={styles.image}>
            <img src={item.itemImages[0]} alt="商品" />
          </div>
          {item.isPublished ? (
            <div className={styles.publishedInfo}>
              <Chip label="已上架" color="primary" variant="outlined" />
              <div className={styles.details}>
                <h4>
                  上架時間: <span>{formatTime(item.publishedAt)}</span>
                </h4>
                <h4>
                  最後更新時間: <span>{formatTime(item.updatedAt)}</span>
                </h4>
                <h4>
                  上架倍率: <span>{item.publishedMagnification}</span>
                </h4>
                <h4>
                  上架匯率: <span>{item.publishedExchangeRate}</span>
                </h4>
              </div>
            </div>
          ) : (
            <Chip label="未上架" disabled />
          )}
        </div>

        <section className={styles.content}>
          {items.currency === 'JPY' ? (
            <div className={styles.prices}>
              {item.sellPrice ? (
                <h4 className={styles.sell_price}>售價: ¥{item.sellPrice * items.multiple}</h4>
              ) : null}
              {item.originPrice ? (
                <React.Fragment>
                  <h4 className={styles.origin_price}>
                    原價: ¥{item.originPrice * items.multiple}
                  </h4>
                </React.Fragment>
              ) : null}
            </div>
          ) : null}
          {items.currency === 'TWD' ? (
            <div className={styles.prices}>
              {item.sellPrice ? (
                <h4 className={styles.sell_price}>
                  售價: NT${item.sellPrice * items.multiple * items.exchangeRate}
                </h4>
              ) : null}
              {item.originPrice ? (
                <React.Fragment>
                  <h4 className={styles.origin_price}>
                    原價: NT${item.originPrice * items.multiple * items.exchangeRate}
                  </h4>
                </React.Fragment>
              ) : null}
            </div>
          ) : null}
          <h1 className={styles.item_name}>{item.itemName}</h1>
          <h4>店舖: {item.shop ? item.shop : '-'}</h4>
          <h4>品牌: {item.brand ? item.brand : '-'}</h4>
          <h4>分類: {item.category ? item.category : '-'}</h4>
          <h4>商品貨號: {item.itemCodeShop ? item.itemCodeShop : item.itemCodeZozo}</h4>
          <h4>關鍵字: {item.itemKeywords}</h4>
          <h4>
            <a href={item.sourceUrl} target="_blank" rel="noreferrer">
              網站來源
            </a>
          </h4>
          <div className={styles.description}>
            <ItemTabs item={item} />
          </div>
        </section>
        <section className={styles.styles_of_products}>
          {item.stylesOfProduct.map((style, index) => (
            <div key={index} className={styles.item}>
              <img src={style.styleImage} alt="商品樣式" />
              <section>
                <h4>{style.optionNameA}</h4>
                <h4 className={styles.size}>
                  size: <span>{style.optionNameB}</span>
                </h4>
                <h4 className={`${styles.stock} ${style.inStock ? styles.inStock : null}`}>
                  {style.inStock ? '有庫存' : '尚無庫存'}
                </h4>
              </section>
            </div>
          ))}
        </section>
      </section>
    </div>
  );
};

export default ItemCard;
