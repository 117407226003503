import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../history';
import dayjs from 'dayjs';
import TaskForm from '../../components/TaskForm';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { fetchTaskList } from '../../actions/crawlerTask';
import { deleteTask } from '../../actions/crawlerTask';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import styles from './index.module.scss';

const TaskTable = () => {
  const dispatch = useDispatch();
  const taskList = useSelector((state) => Object.values(state.crawlerTask));
  const { healthCheck } = useSelector((state) => state);
  const [newTaskDialog, setNewTaskDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const toast = useRef(null);

  useEffect(() => {
    dispatch(fetchTaskList());
  }, []);

  const openNew = () => {
    setNewTaskDialog(true);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const seeTaskResult = (taskId) => {
    history.push(`/result/${taskId}`);
  };

  const deleteSelectedProducts = () => {
    const selectedProductsIds = selectedProducts.map((item) => item.id);
    dispatch(deleteTask(selectedProductsIds));
    setDeleteProductsDialog(false);
    setSelectedProducts([]);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="新增爬蟲任務"
          icon="pi pi-plus"
          className="p-button-success p-mr-2"
          onClick={openNew}
        />

        <Button
          label="刪除爬蟲任務"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedProducts || !selectedProducts.length}
        />
      </React.Fragment>
    );
  };

  const healthCheckTemplate = () => {
    return (
      <div className={`${styles.healthCheck} customTooltip`} y>
        <span className={styles.time}> {dayjs(healthCheck.date).format('YYYY/MM/DD HH:mm')}</span>
        <h3>
          health check{' '}
          {healthCheck.success ? (
            <Tag severity="success" value="正常" rounded />
          ) : (
            <Tag severity="danger" value="異常" rounded />
          )}
        </h3>
      </div>
    );
  };

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">爬蟲任務列表</h5>
    </div>
  );

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="取消"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="確定刪除"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  const statusBodyTemplate = (rowData) => {
    const { status } = rowData;

    if (status === 'normal') {
      return <span className={`${styles.status} ${styles.status_normal}`}>排程中</span>;
    }
    if (status === 'progressing') {
      return <span className={`${styles.status} ${styles.status_progressing}`}>正在爬取</span>;
    }
    if (status === 'abnormal') {
      return <span className={`${styles.status} ${styles.status_abnormal}`}>異常</span>;
    }
    if (status === 'tooManyItems') {
      return (
        <span className={`${styles.status} ${styles.status_tooManyItems}`}>
          商品數過多，已停止爬取
        </span>
      );
    }
    return null;
  };

  const resultBodyTemplate = (rowData) => {
    return (
      <Button
        label="查看商品"
        className="p-button-rounded p-button-success p-mr-2"
        onClick={() => seeTaskResult(rowData.id)}
      />
    );
  };

  const formatTime = (time) => {
    return dayjs(time).format('YYYY/MM/DD HH:mm');
  };

  const createdAtBodyTemplate = (rowData) => {
    return <span>{formatTime(rowData.createdAt)}</span>;
  };

  const updatedAtBodyTemplate = (rowData) => {
    return <span>{formatTime(rowData.updatedAt)}</span>;
  };

  const frequencyBodyTemplate = (rowData) => {
    const oneDay = 86400000;
    const oneHour = 60 * 60 * 1000;

    if (rowData.frequency >= oneDay) return <span>{rowData.frequency / oneDay} 天</span>;
    else return <span>{rowData.frequency / oneHour} 小時</span>;
  };

  const allInStockBodyTemplate = (rowData) => {
    return <span>{rowData.allInStock ? <i className="pi pi-check" /> : null}</span>;
  };

  const isSaleBodyTemplate = (rowData) => {
    return <span>{rowData.isSale ? <i className="pi pi-check" /> : null}</span>;
  };

  const hideDialog = () => {
    setNewTaskDialog(false);
  };

  return (
    <div className={styles.taskTable}>
      <Tooltip target=".customTooltip" position="bottom" />
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={healthCheckTemplate}
        ></Toolbar>
        {taskList.length ? (
          <DataTable
            className="p-datatable-sm"
            value={taskList}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            dataKey="id"
            showGridlines
            header={header}
          >
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
            <Column header="查看爬蟲結果" body={resultBodyTemplate}></Column>
            <Column field="keyword" header="關鍵字" sortable></Column>
            <Column field="shop" header="商店" sortable></Column>
            <Column field="brand" header="品牌" sortable></Column>
            <Column field="sex" header="性別" sortable></Column>
            <Column field="lowestPrice" header="最低價" sortable></Column>
            <Column field="highestPrice" header="最高價" sortable></Column>
            <Column
              field="isSale"
              header="僅顯示特價中"
              sortable
              body={isSaleBodyTemplate}
            ></Column>
            <Column
              field="allInStock"
              header="僅顯示有庫存"
              sortable
              body={allInStockBodyTemplate}
            ></Column>
            <Column
              field="createdAt"
              header="新增任務時間"
              sortable
              body={createdAtBodyTemplate}
            ></Column>
            <Column
              field="updatedAt"
              header="最後更新時間"
              sortable
              body={updatedAtBodyTemplate}
            ></Column>
            <Column field="status" header="爬蟲狀態" body={statusBodyTemplate}></Column>
            <Column field="frequency" header="爬蟲頻率" body={frequencyBodyTemplate}></Column>
          </DataTable>
        ) : (
          <span className="noData">目前無任何爬蟲任務</span>
        )}
      </div>

      <Dialog
        visible={newTaskDialog}
        style={{ width: '850px' }}
        header="新增爬蟲任務"
        modal
        className="p-fluid"
        onHide={hideDialog}
      >
        <TaskForm />
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: '450px' }}
        header={`確定要刪除 ${selectedProducts.length} 個爬蟲任務嗎`}
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          {<span>任務一旦刪除，將會一併刪除該任務的所有商品</span>}
        </div>
      </Dialog>
    </div>
  );
};

export default TaskTable;
