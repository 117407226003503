import { FETCH_SHOP_CONFIGS, FETCH_BRAND_CONFIGS } from '../actions/actionType';

const initialState = {
  shopOptionSet: [],
  brandOptionSet: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SHOP_CONFIGS: {
      return { ...state, shopOptionSet: payload };
    }

    case FETCH_BRAND_CONFIGS: {
      return { ...state, brandOptionSet: payload };
    }

    default: {
      return state;
    }
  }
};
