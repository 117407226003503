import {
  SET_PROGRESS,
  DISABLE_PUBLISH_OR_UPDATE,
  SET_UPDATE_INPROGRESS,
  SET_PUBLISH_INPROGRESS,
} from '../actions/actionType';

const initialState = {
  finishedCount: 0,
  totalCount: 0,
  canPublishOrUpdate: true,
  updateInprogress: false,
  publishInprogress: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PROGRESS: {
      return { ...state, ...payload };
    }

    case DISABLE_PUBLISH_OR_UPDATE: {
      return { ...state, canPublishOrUpdate: !payload.inprogress };
    }

    case SET_UPDATE_INPROGRESS: {
      return { ...state, updateInprogress: payload.inprogress };
    }

    case SET_PUBLISH_INPROGRESS: {
      return { ...state, publishInprogress: payload.inprogress };
    }

    default: {
      return state;
    }
  }
};
