import backend from '../utilts/backendAPI';
import { FETCH_SHOP_CONFIGS, FETCH_BRAND_CONFIGS } from './actionType';

export const fetchShopConfigs = (data) => async (dispatch) => {
  const response = await backend.GET('/configs/shopConfigs', data);

  dispatch({ type: FETCH_SHOP_CONFIGS, payload: response.data });
};

export const fetchBrandConfigs = (data) => async (dispatch) => {
  const response = await backend.GET('/configs/brandConfigs', data);

  dispatch({ type: FETCH_BRAND_CONFIGS, payload: response.data });
};
