import { combineReducers } from 'redux';

import crawlerTask from './crawlerTask';
import options from './options';
import items from './items';
import snackbar from './snackbar';
import loading from './loading';
import progress from './progress';
import healthCheck from './healthCheck';

export default combineReducers({
  crawlerTask,
  options,
  items,
  snackbar,
  loading,
  progress,
  healthCheck,
});
