import backend from '../utilts/backendAPI';
import {
  FETCH_ITEMS,
  MULTIPLY_PRICE,
  CHANGE_EXCHANGE_RATE,
  CHANGE_CURRENCY,
  RESET_ITEMS,
} from './actionType';
import { setProgress, disablePublishOrUpdate } from './progress';
import { setSnackbar } from './snackbar';

export const fetchItems =
  ({ taskId, pageNumber }) =>
  async (dispatch) => {
    const response = await backend.GET(`/tasks/${taskId}/items?page=${pageNumber}`);

    dispatch({ type: FETCH_ITEMS, payload: response });
  };

export const resetItems = () => {
  return { type: RESET_ITEMS, payload: [] };
};

export const fetchUnpublishedItems =
  ({ taskId, pageNumber }) =>
  async (dispatch) => {
    const response = await backend.GET(`/tasks/${taskId}/items?page=${pageNumber}&published=${0}`);
    dispatch({ type: 'FETCH_UNPUBLISHED_ITEMS', payload: response });
  };

export const resetUnpublishedItems = () => {
  return { type: 'RESET_UNPUBLISHED_ITEMS', payload: [] };
};

export const fetchPublishedItems =
  ({ taskId, pageNumber }) =>
  async (dispatch) => {
    const response = await backend.GET(`/tasks/${taskId}/items?page=${pageNumber}&published=${1}`);

    dispatch({ type: 'FETCH_PUBLISHED_ITEMS', payload: response });
  };

export const resetPublishedItems = () => {
  return { type: 'RESET_PUBLISHED_ITEMS', payload: [] };
};

export const deleteItems =
  ({ taskId, itemIds }) =>
  async (dispatch) => {
    try {
      await backend.POST(`/items/bulkDelete`, { itemIds });
      dispatch(fetchPublishedItems({ taskId, pageNumber: 1 }));
      dispatch(fetchUnpublishedItems({ taskId, pageNumber: 1 }));
      dispatch(
        setSnackbar({
          isSnackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: '已刪除所選取的商品',
        })
      );
    } catch (error) {
      dispatch(
        setSnackbar({
          isSnackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: '發生錯誤',
        })
      );
    }
  };

export const changeExchangeRate = ({ exchangeRate }) => {
  return { type: CHANGE_EXCHANGE_RATE, payload: { exchangeRate } };
};

export const changeCurrency = ({ currency }) => {
  return { type: CHANGE_CURRENCY, payload: { currency } };
};

export const multiplyPrice = (multiple) => {
  return { type: MULTIPLY_PRICE, payload: multiple };
};

export const publishByTaskId =
  ({ taskId, multiple, exchangeRate }) =>
  async (dispatch, getState) => {
    const { count } = getState().items.unpublishedItems;
    dispatch(setProgress({ finishedCount: 0, totalCount: count }));
    dispatch(disablePublishOrUpdate({ inprogress: true }));
    await backend.POST(`/tasks/${taskId}/items/publish`, {
      magnification: multiple,
      exchangeRate,
    });
  };

export const publishByGoodsId =
  ({ items, multiple, exchangeRate }) =>
  async (dispatch, getState) => {
    const { selectedUnpublishedItems } = getState().items;
    const totalCount = Object.values(selectedUnpublishedItems).length;
    const itemIds = items.map((item) => item.id);
    dispatch(setProgress({ finishedCount: 0, totalCount }));
    dispatch(disablePublishOrUpdate({ inprogress: true }));

    try {
      await backend.POST(`/items/publishMany`, { itemIds, magnification: multiple, exchangeRate });
    } catch (error) {
      dispatch(
        setSnackbar({
          isSnackbarOpen: true,
          snackbarType: 'warning',
          snackbarMessage: '發生錯誤',
        })
      );
    }
  };

export const updateByTaskId =
  ({ taskId, multiple, exchangeRate }) =>
  async (dispatch, getState) => {
    const { count } = getState().items.publishedItems;
    dispatch(setProgress({ finishedCount: 0, totalCount: count }));
    dispatch(disablePublishOrUpdate({ inprogress: true }));

    await backend.POST(`/tasks/${taskId}/items/updatePublishPrice`, {
      magnification: multiple,
      exchangeRate,
    });
  };

export const updateByGoodsId =
  ({ items, multiple, exchangeRate }) =>
  async (dispatch, getState) => {
    const { selectedPublishedItems } = getState().items;
    const totalCount = Object.values(selectedPublishedItems).length;
    const itemIds = items.map((item) => item.id);

    dispatch(setProgress({ finishedCount: 0, totalCount }));
    dispatch(disablePublishOrUpdate({ inprogress: true }));

    await backend.POST(
      `/items/updateManyPublishPrice
    `,
      {
        itemIds,
        magnification: multiple,
        exchangeRate,
      }
    );
  };

export const selectUnpublishedItem = (data) => {
  return { type: 'SELECT_UNPUBLISHED_ITEM', payload: data };
};

export const resetSelectedItem = () => {
  return { type: 'RESET_SELECTED_ITEMS' };
};

export const removeUnpublishedSelectedItems = (data) => {
  return { type: 'REMOVE_UNPUBLISHED_SELECTED_ITEM', payload: data };
};

export const selectPublishedItem = (data) => {
  return { type: 'SELECT_PUBLISHED_ITEM', payload: data };
};

export const removePublishedSelectedItems = (data) => {
  return { type: 'REMOVE_PUBLISHED_SELECTED_ITEM', payload: data };
};
