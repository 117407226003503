import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import styles from './index.module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ItemTabs({ item }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      {/* <AppBar position="static" color="default"> */}
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="商品描述" {...a11yProps(0)} />
        <Tab label="規格" {...a11yProps(1)} />
        <Tab label="尺寸" {...a11yProps(2)} />
        <Tab label="更多照片" {...a11yProps(3)} />
      </Tabs>
      {/* </AppBar> */}
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {item.itemDescription}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <h4>材質: {item.material}</h4>
          <h4>生產國: {item.madeIn ? item.madeIn : '-'}</h4>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <h4>{item.sizeInfo}</h4>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <div className={styles.more_images}>
            {[...item.itemImages, ...item.moreImages].map((img, index) => (
              <div className={styles.image} key={index}>
                <img src={img} />
              </div>
            ))}
          </div>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
