import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetItems } from '../../actions/items';

import TaskTable from '../../components/TaskTable';

import styles from './index.module.scss';

const Task = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetItems());
  }, []);

  return (
    <div className={styles.task_page}>
      <main>
        <TaskTable />
      </main>
    </div>
  );
};

export default Task;
