import React, { useEffect } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import alphabet from './alphabet';
import styles from './index.module.scss';

const SearchBox = ({ options, fetchConfigs, selectHandler }) => {
  const fetchConfigHandler = (e) => {
    if (e.target.value === '') return;
    else fetchConfigs({ search: e.target.value });
  };

  return (
    <div className={styles.search_box}>
      <TextField
        fullWidth
        id="keyword"
        name="keyword"
        label="關鍵字"
        onChange={fetchConfigHandler}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <section className={styles.alphabet}>
        {alphabet.map((letter) => (
          <span
            key={letter}
            className={styles.letter}
            onClick={() => fetchConfigs({ initial: letter })}
          >
            {letter}
          </span>
        ))}
      </section>
      <div className={styles.search_result}>
        {options.map((item) => (
          <div key={item.url} className={styles.option}>
            <span onClick={() => selectHandler(item.url)}>{item.url}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchBox;
