export const ADD_TASK = 'ADD_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const HEALTH_CHECK = 'HEALTH_CHECK';

export const FETCH_TASK_LIST = 'FETCH_TASK_LIST';
export const SOCKET_UPDATE_TASK_STATUS = 'SOCKET_UPDATE_TASK_STATUS';
export const SOCKET_ADD_TASK = 'SOCKET_ADD_TASK';

export const FETCH_OPTIONS = 'FETCH_OPTIONS';
export const FETCH_ITEMS = 'FETCH_ITEMS';

export const FETCH_SHOP_CONFIGS = 'FETCH_SHOP_CONFIGS';
export const FETCH_BRAND_CONFIGS = 'FETCH_BRAND_CONFIGS';

export const MULTIPLY_PRICE = 'MULTIPLY_PRICE';
export const CHANGE_EXCHANGE_RATE = 'CHANGE_EXCHANGE_RATE';
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';

export const PUBLISH = 'PUBLISH';

export const SET_SNACKBAR = 'SET_SNACKBAR';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const RESET_ITEMS = 'RESET_ITEMS';
export const DELETE_ITEMS = 'DELETE_ITEMS';

export const SET_PROGRESS = 'SET_PROGRESS';
export const DISABLE_PUBLISH_OR_UPDATE = 'DISABLE_PUBLISH_OR_UPDATE';
export const SET_PUBLISH_INPROGRESS = 'SET_PUBLISH_INPROGRESS';
export const SET_UPDATE_INPROGRESS = 'SET_UPDATE_INPROGRESS';
