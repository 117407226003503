import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import * as itemActions from '../../actions/items';
import * as progressActions from '../../actions/progress';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import Pagination from '@material-ui/lab/Pagination';
import { Dialog } from 'primereact/dialog';

import ItemCard from '../ItemCard';
import styles from './index.module.scss';

const unpublishedGoods = () => {
  let match = useRouteMatch('/result/:taskId');
  const dispatch = useDispatch();

  // unpublishedItems.rows = current page items
  const { unpublishedItems } = useSelector((state) => state.items);
  const { progress } = useSelector((state) => state);
  const isSelectedAllCurrentPage = useSelector((state) =>
    state.items.unpublishedItems.rows.every(
      (item) => !!state.items.selectedUnpublishedItems[item.id]
    )
  );

  const selectedUnpublishedItems = useSelector((state) =>
    Object.values(state.items.selectedUnpublishedItems)
  );

  const [displayModal, setDisplayModal] = useState(false);
  const [publishAllModal, setPublishAllModal] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(itemActions.fetchUnpublishedItems({ taskId: match.params.taskId, pageNumber: 1 }));

    return () => {
      dispatch(itemActions.resetUnpublishedItems());
    };
  }, []);

  const validationSchema = yup.object({
    multiple: yup.number().positive().typeError('須為數字').required('必填'),
    exchangeRate: yup.number().positive().typeError('須為數字').required('必填'),
  });

  const formikByTask = useFormik({
    initialValues: {
      multiple: null,
      exchangeRate: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const taskId = unpublishedItems.rows[0].taskId;
      dispatch(itemActions.publishByTaskId({ taskId, ...values }));
      dispatch(progressActions.setPublishInprogress({ inprogress: true }));
      setCurrentPage(1);
      Object.keys(dialogFuncMap).map((name) => onHide(name));
      formikByTask.handleReset();
    },
  });

  const formikByGoods = useFormik({
    initialValues: {
      multiple: null,
      exchangeRate: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(itemActions.publishByGoodsId({ items: selectedUnpublishedItems, ...values }));
      dispatch(progressActions.setPublishInprogress({ inprogress: true }));

      setCurrentPage(1);
      Object.keys(dialogFuncMap).map((name) => onHide(name));
      formikByGoods.handleReset();
    },
  });

  const handleSelectAllClick = (event) => {
    const arrItemIds = unpublishedItems.rows.map((item) => item.id);

    if (isSelectedAllCurrentPage) {
      dispatch(itemActions.removeUnpublishedSelectedItems({ itemIds: arrItemIds }));
    } else {
      unpublishedItems.rows.map((item) => dispatch(itemActions.selectUnpublishedItem(item)));
    }
  };

  /* 上架 & 更新上架 & 刪除後，一律回到分頁的第 1 頁 */
  const handleDelete = () => {
    const arrItemIds = selectedUnpublishedItems.map((item) => item.id);
    dispatch(itemActions.deleteItems({ taskId: match.params.taskId, itemIds: arrItemIds }));
    dispatch(itemActions.removeUnpublishedSelectedItems({ itemIds: arrItemIds }));
    setCurrentPage(1);
    onHide('deleteConfirmModal');
  };

  const isSelected = (selectedItem) =>
    selectedUnpublishedItems.find((item) => item.id === selectedItem.id);

  const handleCheckboxChange = (selectedItem) => {
    const isItemChecked = selectedUnpublishedItems.find((item) => item.id === selectedItem.id);
    if (isItemChecked) {
      dispatch(itemActions.removeUnpublishedSelectedItems({ itemIds: [selectedItem.id] }));
    } else {
      dispatch(itemActions.selectUnpublishedItem(selectedItem));
    }
  };

  const dialogFuncMap = {
    displayModal: setDisplayModal,
    publishAllModal: setPublishAllModal,
    deleteConfirmModal: setDeleteConfirmModal,
  };

  const onClick = (name) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const renderTaskFooter = (name, handleConfirm) => {
    return (
      <div>
        <Button
          type="button"
          label="取消"
          icon="pi pi-times"
          onClick={() => {
            formikByTask.resetForm();
            Object.keys(dialogFuncMap).map((key) => onHide(key));
          }}
          className="p-button-text"
        />
        <Button
          type="submit"
          label="確認"
          icon="pi pi-check"
          onClick={() => {
            if (handleConfirm) {
              handleConfirm();
            }
          }}
          autoFocus
        />
      </div>
    );
  };

  const renderGoodsFooter = (name, handleConfirm) => {
    return (
      <div>
        <Button
          type="button"
          label="取消"
          icon="pi pi-times"
          onClick={() => {
            formikByGoods.resetForm();
            Object.keys(dialogFuncMap).map((key) => onHide(key));
          }}
          className="p-button-text"
        />
        <Button
          type="submit"
          label="確認"
          icon="pi pi-check"
          onClick={() => {
            if (handleConfirm) {
              handleConfirm();
            }
          }}
          autoFocus
        />
      </div>
    );
  };

  return (
    <div>
      {!unpublishedItems.rows.length ? (
        <h4 className="noData">無任何資料</h4>
      ) : (
        <React.Fragment>
          <main className={styles.main}>
            <div className={styles.toolBar}>
              <Checkbox
                onChange={handleSelectAllClick}
                checked={isSelectedAllCurrentPage}
              ></Checkbox>
              <Button
                label={`上架 ${selectedUnpublishedItems.length} 件商品`}
                icon="pi pi-shopping-cart"
                className="p-button-rounded p-button-success"
                onClick={() => onClick('displayModal')}
                disabled={selectedUnpublishedItems.length === 0 || !progress.canPublishOrUpdate}
              />
              <Button
                label={`刪除 ${selectedUnpublishedItems.length} 件商品`}
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger"
                onClick={() => onClick('deleteConfirmModal')}
                disabled={selectedUnpublishedItems.length === 0}
              />
              {unpublishedItems.rows.length ? (
                <Button
                  label="上架全部商品"
                  icon="pi pi-shopping-cart"
                  className="p-button-rounded p-button-info"
                  disabled={!progress.canPublishOrUpdate}
                  onClick={() => onClick('publishAllModal')}
                />
              ) : null}
              <Dialog
                header={`上架所選取的 ${selectedUnpublishedItems.length} 件商品`}
                visible={displayModal}
                modal={false}
                closable={false}
                disabled={!progress.canPublishOrUpdate}
                footer={renderGoodsFooter('displayModal', formikByGoods.submitForm)}
                onHide={() => onHide('displayModal')}
              >
                <form autoComplete="off" onSubmit={formikByGoods.handleSubmit}>
                  <div className={styles.input_wrapper}>
                    <TextField
                      type="number"
                      name="multiple"
                      label="倍率"
                      value={formikByGoods.multiple}
                      error={
                        formikByGoods.touched.multiple && Boolean(formikByGoods.errors.multiple)
                      }
                      onChange={formikByGoods.handleChange}
                      helperText={formikByGoods.touched.password && formikByGoods.errors.password}
                    />
                  </div>
                  <div className={styles.input_wrapper}>
                    <TextField
                      type="number"
                      name="exchangeRate"
                      label="匯率"
                      value={formikByGoods.exchangeRate}
                      error={
                        formikByGoods.touched.exchangeRate &&
                        Boolean(formikByGoods.errors.exchangeRate)
                      }
                      onChange={formikByGoods.handleChange}
                      helperText={
                        formikByGoods.touched.exchangeRate && formikByGoods.errors.exchangeRate
                      }
                    />
                  </div>
                </form>
              </Dialog>
              <Dialog
                header={`上架所有商品(共 ${unpublishedItems.count} 件)`}
                visible={publishAllModal}
                modal={false}
                closable={false}
                footer={renderTaskFooter('publishAllModal', formikByTask.submitForm)}
                onHide={() => onHide('publishAllModal')}
              >
                <form autoComplete="off" onSubmit={formikByTask.handleSubmit}>
                  <div className={styles.input_wrapper}>
                    <TextField
                      type="number"
                      name="multiple"
                      label="倍率"
                      value={formikByTask.multiple}
                      error={formikByTask.touched.multiple && Boolean(formikByTask.errors.multiple)}
                      onChange={formikByTask.handleChange}
                      helperText={formikByTask.touched.password && formikByTask.errors.password}
                    />
                  </div>
                  <div className={styles.input_wrapper}>
                    <TextField
                      type="number"
                      name="exchangeRate"
                      label="匯率"
                      value={formikByTask.exchangeRate}
                      error={
                        formikByTask.touched.exchangeRate &&
                        Boolean(formikByTask.errors.exchangeRate)
                      }
                      onChange={formikByTask.handleChange}
                      helperText={
                        formikByTask.touched.exchangeRate && formikByTask.errors.exchangeRate
                      }
                    />
                  </div>
                </form>
              </Dialog>
              <Dialog
                header={`確定要刪除 ${selectedUnpublishedItems.length} 件商品嗎？`}
                visible={deleteConfirmModal}
                modal={false}
                closable={false}
                footer={renderGoodsFooter('deleteConfirmModal', handleDelete)}
                onHide={() => onHide('deleteConfirmModal')}
              ></Dialog>
            </div>
            {unpublishedItems.rows.map((item) => {
              const isItemSelected = isSelected(item);
              return (
                <div key={item.id} className={styles.itemContainer}>
                  <Checkbox
                    onChange={() => handleCheckboxChange(item)}
                    checked={isItemSelected}
                  ></Checkbox>
                  <ItemCard
                    key={item.id}
                    item={item}
                    currency={unpublishedItems.currency}
                    showConverterForm={false}
                  />
                </div>
              );
            })}
          </main>
          <footer>
            <div className="pagination">
              <Pagination
                count={unpublishedItems.totalPages}
                color="primary"
                page={currentPage}
                onChange={(e, page) => {
                  dispatch(
                    itemActions.fetchUnpublishedItems({
                      taskId: match.params.taskId,
                      pageNumber: page,
                    })
                  );
                  setCurrentPage(page);
                  window.scrollTo(0, 0);
                }}
              />
              <span className="total_count">共 {unpublishedItems.count} 筆</span>
            </div>
          </footer>
        </React.Fragment>
      )}
    </div>
  );
};

export default unpublishedGoods;
