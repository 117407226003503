import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import Converter from '../../components/Converter';
// import AllGoods from '../GoodsAll';
import UnpublishedGoods from '../GoodsUnpublished';
import GoodsPublished from '../GoodsPublished';

const ResultTabView = () => {
  return (
    <div className="tabview-demo">
      <div className="card">
        <TabView>
          <TabPanel header="未上架商品">
            <UnpublishedGoods />
          </TabPanel>
          <TabPanel header="已上架商品">
            <GoodsPublished />
          </TabPanel>
          {/* <TabPanel header="所有商品">
            <AllGoods />
          </TabPanel> */}
        </TabView>
      </div>
    </div>
  );
};

export default ResultTabView;
