import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { multiplyPrice, changeCurrency, changeExchangeRate } from '../../actions/items';

import { Button } from 'primereact/button';
import TextField from '@material-ui/core/TextField';

import styles from './index.module.scss';

const Converter = () => {
  const dispatch = useDispatch();
  const { items } = useSelector((state) => state);

  const multiplyPriceHandler = (e) => {
    if (!e.target.value) dispatch(multiplyPrice(1));
    else dispatch(multiplyPrice(+e.target.value));
  };

  const handleChangeExchangeRate = (e) => {
    if (!e.target.value) dispatch(changeExchangeRate({ exchangeRate: 1 }));
    else dispatch(changeExchangeRate({ exchangeRate: +e.target.value }));
  };

  return (
    <section className={styles.convert_section}>
      <div className={styles.input_wrapper}>
        <TextField
          id="multiple"
          defaultValue={items.multiple}
          type="number"
          name="multiple"
          label="倍率"
          onChange={multiplyPriceHandler}
        />
      </div>
      <div className={styles.input_wrapper}>
        <TextField
          id="exchange"
          defaultValue={items.exchangeRate}
          type="number"
          name="exchange"
          label="匯率(JPY/TWD)"
          onChange={handleChangeExchangeRate}
        />
        <Button
          label="日幣試算"
          className={`p-button-outlined p-button-rounded ${
            items.currency === 'JPY' ? 'p-button-primary' : 'p-button-secondary'
          }`}
          onClick={() => dispatch(changeCurrency({ currency: 'JPY' }))}
        />
        <Button
          label="台幣試算"
          className={`p-button-outlined  p-button-rounded ${
            items.currency === 'TWD' ? 'p-button-primary' : 'p-button-secondary'
          }`}
          onClick={() => dispatch(changeCurrency({ currency: 'TWD' }))}
        />
      </div>
    </section>
  );
};

export default Converter;
